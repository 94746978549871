@import 'src/styles/_index.scss';

.selected-vehicle-info-v2-rct-component {
  background: $gray-500;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  margin-bottom: 16px;
  margin-top: 24px;
  padding: 16px;
  position: absolute;
  right: 76px;
  z-index: 10;

  @media only screen and (max-width: $width-breakpoint-xs) {
    margin: 0;
    position: initial;
  }

  .selected-vehicle-info__first_row {
    display: flex;
    gap: 16px;

    @media only screen and (max-width: $width-breakpoint-xs) {
      flex-direction: column;
    }

    @media only screen and (max-width: $width-breakpoint-xs) {
      hr {
        margin: 0;
        width: 100%;
      }
    }

    .selected-vehicle-info {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      gap: 16px;
      height: fit-content;
      line-height: 24px;

      @media only screen and (max-width: $width-breakpoint-xs) {
        justify-content: space-between;
      }

      &__pricing {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__label {
          color: #CCCCCC;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }

        &__value {
          color: white;
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
        }
      }

      .vehicle-model-buttons {
        align-items: center;
        display: flex;
        justify-content: center;

        .vehicle-model-button {
          background-color: #555555;
          height: 40px;
          padding: 4px;

          &__content {
            align-items: center;
            color: #FFFFFF;
            display: flex;
            font-size: 16px;
            font-weight: 400;
            height: 100%;
            justify-content: center;
            padding: 4px 8px;
            width: 100%;

            &.selected {
              background-color: #BEE5FF;
              color: #393939;
              font-weight: 600;
            }
          }
        }
      }

      > ::after {
        content: '';
        flex: auto;
      }

      > :last-child {
        margin-top: 16px;

        span {
          color: $text-02;
        }
      }

      .edit-selected-vehicle {
        align-items: center;
        display: flex;
        gap: 8px;
        justify-content: center;
        width: fit-content;
      }
    }
  }

  .selected-vehicle-info__second_row {
    button {
      background-color: #BEE5FF;
      color: #393939;
      height: 56px;
      width: 100%;
    }
  }
}
