@import 'src/styles/_index.scss';

.selected-vehicle-info-field-v2 {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__label {
    color: #CCCCCC;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    @media only screen and (max-width: $width-breakpoint-xs) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__value {
    color: white;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;

    @media only screen and (max-width: $width-breakpoint-xs) {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
