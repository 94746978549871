@import 'src/styles/_index';

.header-nav-rct-component {
  align-items: center;
  background-color: #131313;
  border-bottom: 1px solid #393939;
  display: flex;
  justify-content: space-between;
  padding: 24px 30px;
  z-index: 11;

  &--transparent {
    background-color: transparent;
    border-bottom: 1px solid #D9D9D9;
    padding: 24px 40px;
  }

  .vehicle-picker {
    display: flex;
  }

  .cart {
    align-items: center;
    background-color: rgba(255, 255, 255, 10%);
    border-radius: 50px;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    padding: 16px 26px;

    :first-child {
      margin-right: 10px;
    }
  }

  &__navigation {
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    gap: 40px;
    line-height: 24px;
    padding-bottom: 4px;

    &__active {
      border-bottom: 2px solid $cool-blue;
    }
  }
}
