@import 'src/styles/_index';

.car-configurator-image-carousel-v2 {
  @media only screen and (max-width: $width-breakpoint-xs) {
    .carousel__slides-container {
      min-width: auto;

      > div:first-child {
        margin-left: 0;
      }
    }
  }

  .carousel__embla-ref .embla__slide__inner {
    display: flex;
    justify-content: center;
  }

  .carousel__embla-ref {
    max-width: unset;
  }

  .navigation-arrow {
    &.navigation-arrow {
      background: rgba(255, 255, 255, 72%);
      height: 168px;
      padding: 16px;
      width: 168px;
      z-index: 100;

      @media only screen and (max-width: $width-breakpoint-lgm) {
        height: 128px;
        width: 128px;
      }

      @media only screen and (max-width: $width-breakpoint-mdm) {
        height: 96px;
        width: 96px;
      }

      @media only screen and (max-width: $width-breakpoint-sm) {
        height: 72px;
        width: 72px;
      }

      @media only screen and (max-width: $width-breakpoint-xs) {
        height: 32px;
        width: 32px;

        path {
          stroke-width: 1px;
        }
      }

      path {
        stroke-width: 0.5px;
      }

      &__left {
        left: 32px;
        position: absolute;
      }

      &__right {
        position: absolute;
        right: 32px;
      }
    }
  }

  .image-carousel-slide {
    height: calc(100vh - 89px);
    object-fit: cover;
    width: 100%;

    @media only screen and (max-width: $width-breakpoint-xs) {
      height: auto;
      object-fit: contain;
    }
  }
}
