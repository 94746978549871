@import '../configurator-common.scss';

.simple-car-configurator-v2-rct-component {
  background-color: #393939;
  height: 100%;
  overflow: hidden;

  &__main-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .header-nav-rct-component {
      background: hsl(0deg, 0%, 100%);
      gap: 50px;
    }

    .header-nav-rct-component .logo {
      svg {
        color: black;
      }
    }
  }

  &__scene-container {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    position: relative;
  }

  .value {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: space-between;

    .floating-order-button-container {
      align-items: center;
      display: flex;
      justify-content: center;

      &__button {
        align-items: center;
        display: flex;
        height: 64px;
        justify-content: center;
        margin: 0;
        width: 140px;
      }
    }
  }

  .wrap-picker-rct-component {
    justify-content: space-between;
    max-width: 100%;

    &__texture-picker {
      @media only screen and (min-width: $width-breakpoint-xl) {
        max-width: 1164px;
      }

      @media only screen and (min-width: $width-breakpoint-lg) and (max-width: $width-breakpoint-xl) {
        max-width: 792px;
      }

      @media only screen and (min-width: $width-breakpoint-sm) and (max-width: $width-breakpoint-lg) {
        max-width: 668px;
      }

      @media only screen and (max-width: $width-breakpoint-sm) {
        max-width: 544px;
      }

      @media only screen and (max-width: $width-breakpoint-xs) {
        max-width: 305px;

        .carousel__slides-container {
          div {
            max-width: calc(250px / 4);
          }
        }
      }

      &__texture {
        height: 40px;
        width: 60px;

        img {
          border-radius: 0;
          height: 40px;
          width: 60px;
        }

        &--selected {
          img {
            transform: scale(0.95);
          }

          .texture-selection-border {
            border-radius: 0;
            height: 40px;
            width: 60px;
          }
        }
      }
    }

    &__options {
      max-width: 20%;
    }

    .carousel__embla-ref {
      max-width: unset;
    }
  }
}

.simple-car-configurator-v2-rct-component {
  .car-configurator-header {
    @media only screen and (max-width: $width-breakpoint-lg) {
      gap: 0;
    }
  }
}

@media only screen and (max-width: $width-breakpoint-lg) {
  .simple-car-configurator-v2-rct-component {
    &__main-content {
      .header-nav-rct-component {
        margin: 0;
        padding: 24px 16px;
      }
    }

    &__scene-container {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: $width-breakpoint-xs) {
  .simple-car-configurator-v2-rct-component {
    .sidebar-configurator-footer {
      bottom: unset;
      left: unset;
      position: relative;
      width: 100%;
    }

    .car-configurator-header__hamburger-header {
      justify-content: center;
    }

    .floating-wrap-picker-container {
      background: white;
      width: 100vw;

      .wrap-picker-rct-component {
        flex-direction: column;
        gap: 0;
        margin-bottom: 32px;

        &__options {
          align-items: start;
          align-items: center;
          justify-content: center;
          max-width: 100%;
          padding: 16px 0;
        }
      }
    }

    .sidebar-configurator-footer {
      &__total {
        &__value.value {
          font-size: 20px;
          height: 32px;
        }
      }

      .footer-config-id {
        gap: 0;

        .value {
          height: 32px;
        }

        .floating-order-button-container {
          button {
            bottom: 8px;
            position: absolute;
            right: 16px;
          }
        }
      }
    }
  }

  .scene-picker {
    margin-bottom: 106px;
  }
}

#floating-sidebar-modal {
  .simple-car-configurator-floating-sidebar {
    .selected-vehicle-info-rct-component {
      margin-top: 0;
      padding-left: 40px;
      padding-right: 24px;
    }
  }
}
